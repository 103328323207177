import { withDependencies } from '@wix/thunderbolt-ioc'
import type { ITpaSideEffects } from './types'
import { name, TpaSymbol } from '../symbols'
import { IModelUpdatesHandlersImplementor } from 'ds-feature-model-updates-invoker'
import { ITpa } from '../types'

export const TpaSideEffects = withDependencies(
	[TpaSymbol],
	({ rebuildTpasSrc }: ITpa): IModelUpdatesHandlersImplementor<ITpaSideEffects> => {
		return {
			featureName: name,
			handlers: {
				// TODO PLAT-1322
				rebuildAllTpasSrc: () => setTimeout(rebuildTpasSrc, 0),
			},
		}
	}
)
