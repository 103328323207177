import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LifeCycle, TpaHandlerProviderSymbol, WixCodeSdkHandlersProviderSym } from '@wix/thunderbolt-symbols'
import { TpaHandlersManager } from './tpaHandlersManager'
import { TpaBroadcastManager } from './tpaBroadcastManager'
import {
	TpaModalSymbol,
	TpaEventsListenerManagerSymbol,
	TpaPopupSymbol,
	TpaFullScreenModeSymbol,
	TpaSymbol,
	TpaComponentApiSymbol,
	IFrameStartedLoadingReporterSymbol,
} from './symbols'
import { handlers, dmSupportedHandlers } from './handlers'
import { TpaEventsListenerManager } from './eventsListenerManager'
import { TpaPopupFactory } from './tpaPopup'
import { PublicApiTPAHandler } from './publicApiTPAHandler'
import { TpaModal } from './tpaModal'
import { SiteScrollDispatcher } from './siteScrollDispatch'
import { TpaFullScreenMode } from './tpaFullScreenMode'
import { tpaCommonConfigUpdater } from './tpaCommonConfigUpdater'
import { TpaHandlersManagerSymbol } from 'feature-tpa-commons'
import { TpaPageNavigationDispatcher } from './TpaPageNavigationDispatcher'
import { Tpa } from './tpa'
import { TpaStateManager } from './tpaStateManager'
import { UrlChangeHandlerForPage } from 'feature-router'
import { TpaCurrentCurrencyManager } from './tpaCurrentCurrencyManager'
import { TpaComponentApi } from './tpaComponentApi'
import { TpaDs } from './tpa.ds'
import { ComponentWillMountSymbol } from 'feature-components'
import { TpaComponentWillMount } from './tpaComponentWillMount'
import { IFrameStartedLoadingReporter } from './iframeStartedLoadingReporter'
import { ModelUpdatesHandlersImplementor } from 'ds-feature-model-updates-invoker'
import { TpaSideEffects } from './ds/tpaSideEffects'

export const page: ContainerModuleLoader = (bind) => {
	bind(TpaComponentApiSymbol).to(TpaComponentApi)
	bind(IFrameStartedLoadingReporterSymbol).to(IFrameStartedLoadingReporter)
	bind(LifeCycle.PageWillMountHandler, TpaSymbol).to(Tpa)
	bind(ComponentWillMountSymbol).to(TpaComponentWillMount)
	bind(TpaHandlersManagerSymbol).to(TpaHandlersManager)
	bind(TpaHandlerProviderSymbol, WixCodeSdkHandlersProviderSym, LifeCycle.PageWillUnmountHandler).to(
		PublicApiTPAHandler
	)
	bind(TpaEventsListenerManagerSymbol).to(TpaEventsListenerManager)
	bind(LifeCycle.AppDidLoadPageHandler).to(TpaPageNavigationDispatcher)
	bind(LifeCycle.PageDidMountHandler, LifeCycle.PageDidUnmountHandler, TpaPopupSymbol).to(TpaPopupFactory)
	handlers.forEach((factory) => {
		// TODO fetch and bind only handlers we need https://jira.wixpress.com/browse/PLAT-715
		bind(TpaHandlerProviderSymbol).to(factory)
	})
	bind(LifeCycle.PageDidMountHandler, LifeCycle.PageDidUnmountHandler).to(TpaBroadcastManager)
	bind(LifeCycle.PageDidMountHandler, TpaModalSymbol).to(TpaModal)
	bind(LifeCycle.PageWillMountHandler).to(SiteScrollDispatcher)
	bind(TpaFullScreenModeSymbol).to(TpaFullScreenMode)
	bind(LifeCycle.PageWillMountHandler).to(tpaCommonConfigUpdater)
	bind(UrlChangeHandlerForPage).to(TpaStateManager)
	bind(UrlChangeHandlerForPage).to(TpaCurrentCurrencyManager)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind(TpaComponentApiSymbol).to(TpaComponentApi)
	bind(TpaSymbol).to(TpaDs)
	bind(ComponentWillMountSymbol).to(TpaComponentWillMount)
	bind(TpaHandlersManagerSymbol).to(TpaHandlersManager)
	bind(TpaEventsListenerManagerSymbol).to(TpaEventsListenerManager)
	// TODO: correct implementation for editor #PLAT-1291
	bind(TpaModalSymbol).toConstantValue({
		isModal: () => false,
		openModal: () => Promise.resolve(),
		closeModal: () => {},
	})
	// TODO should use the public segment handlers list instead
	dmSupportedHandlers.forEach((factory) => {
		bind(TpaHandlerProviderSymbol).to(factory)
	})
	bind(ModelUpdatesHandlersImplementor).to(TpaSideEffects)
}

export type {
	ITpaPopup,
	TpaPopupOrigin,
	TpaPopupPlacement,
	OpenModalOptions,
	BaseResponse,
	ITpaModal,
	OpenPopupOptions,
	ITpaFullScreenMode,
	ITPAEventsListenerManager,
} from './types'
export { TpaPopupSymbol, TpaEventsListenerManagerSymbol }
export { TpaModalSymbol, TpaSymbol }
export { TpaFullScreenModeSymbol }
